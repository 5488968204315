import { useI18next } from "gatsby-plugin-react-i18next";
import { useEffect, useState } from "react";
import { generateRoute } from "../utils/generateRoute";

const useRoutes = () => {
  const { language } = useI18next();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted) {
    return generateRoute(language === "es" ? `/es` : "");
  }
  return null;
};

export default useRoutes;
