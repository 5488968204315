import Auth from "@aws-amplify/auth";
import { useEffect, useState } from "react";

export const useGetToken = ({ onComplete } = {}) => {
  const [token, setToken] = useState(false);
  useEffect(() => {
    getToken().then((token) => {
      setToken(token);
      if (onComplete) {
        onComplete();
      }
    });
  }, [onComplete]);
  return token;
};

export const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    const token = await session.getIdToken().getJwtToken();
    return token;
  } catch (error) {
    console.log(error);
    if (error?.message === "Refresh Token has expired") {
      await Auth.signOut();
    }
    if (error?.message === "No current user") {
      await Auth.signOut();
    }
    return false;
  }
};

export const logout = async () => {
  await Auth.signOut();
  window.location.href = `${process.env.GATSBY_REDIRECT_URL}/signin`;
};
