import React from "react";
import { Trans } from "react-i18next";
import {
  Copyright,
  FooterBase,
  FooterContainer,
  FooterLink,
  MenuItems,
} from "./Footer.styles";
import useRoutes from "../../hooks/useRoutes";
import { useI18next } from "gatsby-plugin-react-i18next";

export const Footer = ({ dark }) => {
  const { language } = useI18next();
  const routes = useRoutes();

  const helpUrl = `https://help.prospera.hn/${
    language === "es" ? "es" : ""
  }`;

  return (
    <FooterBase $dark={dark}>
      <FooterContainer>
        <MenuItems>
          <FooterLink
            $dark={dark}
            id="help-and-support"
            variant="p-sm"
            plain
            rightIcon="launch"
            href={helpUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>common.help_and_support</Trans>
          </FooterLink>
          <FooterLink
            $dark={dark}
            id="privacy-policy"
            variant="p-sm"
            plain
            href={routes?.privacyPolicy}
          >
            <Trans>terms.privacy_policy</Trans>
          </FooterLink>
          <FooterLink
            $dark={dark}
            id="terms-of-service"
            variant="p-sm"
            plain
            href={routes?.termsOfService}
          >
            <Trans>common.terms_of_service</Trans>
          </FooterLink>
          <FooterLink
            $dark={dark}
            id="legal-stability-disclosure-and-disclaimer"
            variant="p-sm"
            plain
            href={routes?.legalStabilityDisclosure}
          >
            <Trans>common.legal_stability_disclosure_and_disclaimer</Trans>
          </FooterLink>
        </MenuItems>
        <Copyright $dark={dark}>
          ©
          <Trans components={{ year: new Date().getFullYear() }}>
            common.all_rights_reserved
          </Trans>{" "}
          <Trans>common.is_registered_trademark</Trans>
        </Copyright>
      </FooterContainer>
    </FooterBase>
  );
};

export default Footer;
