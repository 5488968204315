import useBreakpoints from "@vertikal/e-prospera.hooks.use-breakpoints";
import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import {
  MainMenu as EPMainMenu,
  MainMenuContainer,
} from "@vertikal/e-prospera.ui.main-menu";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import useRoutes from "../../hooks/useRoutes";
import logo from "../../images/ep-logo.svg";
import { useGetToken } from "../../utils/authentication";
import Footer from "../Footer/Footer";
import LanguageSelector from "./LanguageSelector";
import { BottomContent, Layout } from "./MainMenu.styles";

const MainMenu = ({ darkFooter, children }) => {
  const isDOM =
    typeof window !== "undefined" && typeof document !== "undefined";

  const path = isDOM ? window.location.href : "";
  const breakpoints = useBreakpoints();
  const routes = useRoutes();

  const [mounted, setMounted] = useState(false);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    setMounted(true);
  }, []);

  let navItems = [
    {
      id: "menu-item-residency",
      name: <Trans>common.residency</Trans>,
      href: routes?.residency,
      active: path.includes(routes?.residency),
    },
    {
      id: "menu-item-business",
      name: <Trans>common.business</Trans>,
      active:
        path.includes(routes?.business) ||
        path.includes(routes?.businessGetStarted),
      children: [
        {
          id: "menu-item-business-get-started",
          name: <Trans>common.get_started</Trans>,
          href: routes?.businessGetStarted,
          active:
            path.includes(routes?.businessGetStarted) &&
            !path.includes(routes?.businessSearch),
        },
        {
          id: "menu-item-business-search",
          name: <Trans>common.business_search</Trans>,
          href: routes?.businessSearch,
          active: path.includes(routes?.businessSearch),
        },
      ],
    },
    {
      id: "menu-item-taxes",
      name: <Trans>common.taxes</Trans>,
      href: routes?.tax,
      active: path.includes(routes?.tax),
    },
    {
      id: "menu-item-marketplace",
      name: <Trans>common.marketplace</Trans>,
      href: routes?.marketplace,
      active: path.includes(routes?.marketplace),
    },
    {
      id: "menu-item-more",
      name: <Trans>common.more</Trans>,
      active: path.includes(routes?.community) || path.includes(routes?.about),
      children: [
        {
          id: "menu-item-community",
          name: <Trans>common.community</Trans>,
          href: routes?.community,
          active: path.includes(routes?.community),
        },
        {
          id: "menu-item-about",
          name: <Trans>common.about</Trans>,
          href: routes?.about,
          active: path.includes(routes?.about),
        },
      ],
    },
  ];

  if (mounted && breakpoints.lessThan.lg) {
    navItems.unshift({
      id: "menu-item-home",
      name: <Trans>common.home</Trans>,
      href: routes?.root,
      active: path === routes?.root,
    });
  }

  const { language } = useI18next();

  const token = useGetToken({ onComplete: () => setUserLoading(false) });

  const getSignInLink = (lang = "en") => {
    if (lang === "es") {
      return `${process.env.GATSBY_REDIRECT_URL}/signin?lang=es`;
    }
    return `${process.env.GATSBY_REDIRECT_URL}/signin`;
  };

  const renderCTA = (size, fullWidth) => {
    return (
      <Button
        id="dashboard-button"
        fullWidth={fullWidth}
        size={size}
        loading={userLoading}
        href={token ? process.env.GATSBY_REDIRECT_URL : getSignInLink(language)}
        focusColor="light"
      >
        {userLoading ? (
          "loading"
        ) : (
          <Trans>{token ? "user_dashboard.dashboard" : "common.sign_in"}</Trans>
        )}
      </Button>
    );
  };

  return (
    <MainMenuContainer
      variant="header"
      theme="very-dark"
      maxHeaderWidth="1600px"
      headerLogoCenter
      mainMenu={
        <EPMainMenu
          logo={logo}
          logoAlt="eProspera logo"
          theme="light"
          items={navItems}
          headerRightContent={
            <>
              {!token && !userLoading ? (
                <LanguageSelector />
              ) : (
                <Box width="3.5rem" />
              )}
              {breakpoints.greaterThan.lg && (
                <Box marginLeft={1} marginRight={1}>
                  {renderCTA("small")}
                </Box>
              )}
            </>
          }
          bottomContent={
            breakpoints.lessThan.lg && (
              <BottomContent>{renderCTA("medium", true)}</BottomContent>
            )
          }
        />
      }
    >
      <Layout>
        {children}
        <Footer dark={darkFooter} />
      </Layout>
    </MainMenuContainer>
  );
};

export default MainMenu;
