import { HeaderButton } from "@vertikal/e-prospera.ui.main-menu";
import { useI18next } from "gatsby-plugin-react-i18next";
import Cookies from "js-cookie";
import React from "react";
import { LanguageDropdown } from "./MainMenu.styles";

const options = [
  { name: "English", value: "en" },
  { name: "Espanol", value: "es" },
];

const getLangName = ({ value }) => {
  return options.find((el) => el.value === value).name;
};

const LanguageSelector = () => {
  const { changeLanguage, language, originalPath } = useI18next();

  return (
    <LanguageDropdown
      options={options}
      id="language-select"
      getValue={(val) => {
        Cookies.set("user-preferred-language", val, {
          domain:
            window.location.hostname === "localhost"
              ? "localhost"
              : "eprospera.hn",
          secure: window.location.hostname !== "localhost",
          path: "/",
          expires: 7,
          sameSite: "lax",
        });
        changeLanguage(val, originalPath === "/" ? originalPath : undefined);
      }}
    >
      {({ openDropdown }) => (
        <div>
          <HeaderButton
            id="language-select"
            onClick={() => openDropdown()}
            icon="language"
            rightIcon="arrow_drop_down"
          >
            {getLangName({ value: language })}
          </HeaderButton>
        </div>
      )}
    </LanguageDropdown>
  );
};

export default LanguageSelector;
