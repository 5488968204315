import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors from "@vertikal/e-prospera.styles.colors";
import Link from "@vertikal/e-prospera.ui.link";
import Text from "@vertikal/e-prospera.ui.text";
import styled from "styled-components";

export const FooterBase = styled.div`
  padding: 1.5rem;
  background: ${(props) => props.$dark && colors.accent.c900};
  @media ${breakpoints.md} {
    padding: 2.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  margin: auto;
  max-width: 1600px;
  flex-direction: column;
  @media ${breakpoints.md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  @media ${breakpoints.md} {
    flex-direction: row;
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
  @media ${breakpoints.mdDown} {
    margin-bottom: 2rem;
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const FooterLink = styled(Link)`
  color: ${(props) => (props.$dark ? colors.gray.c300 : colors.gray.c600)};
  &:hover {
    color: ${(props) => (props.$dark ? colors.gray.c200 : colors.gray.c500)};
  }
`;

export const Copyright = styled(Text).attrs(() => ({ variant: "p-xs" }))`
  color: ${(props) => (props.$dark ? colors.gray.c300 : colors.gray.c600)};
`;
