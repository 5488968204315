import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import Dropdown from "@vertikal/e-prospera.ui.dropdown";
import styled from "styled-components";

export const BottomContent = styled.div`
  margin: 1.5rem;
`;

export const LanguageDropdown = styled(Dropdown)`
  max-width: 150px;
`;

export const Layout = styled.div`
  position: relative;
  @media ${breakpoints.md} {
    box-sizing: border-box;
    min-height: calc(100vh - 5rem);
    padding-bottom: 5rem;
  }
`;
