export const generateRoute = (lang) => {
  const isDOM =
    typeof window !== "undefined" && typeof document !== "undefined";

  if (isDOM) {
    return {
      root: `${window.location.origin}${lang}`,
      business: `${window.location.origin}${lang}/entities`,
      businessGetStarted: `${window.location.origin}${lang}/business`,
      businessSearch: `${window.location.origin}${lang}/business/search`,
      tax: `${window.location.origin}${lang}/tax`,
      marketplace: `${window.location.origin}${lang}/marketplace`,
      community: `${window.location.origin}${lang}/community`,
      privacyPolicy: `${window.location.origin}${lang}/privacy-policy`,
      termsOfService: `${window.location.origin}${lang}/terms-of-service`,
      legalStabilityDisclosure: `${window.location.origin}${lang}/legal-stability-disclosure`,
      about: `${window.location.origin}${lang}/about`,
      insuredNomads: `${window.location.origin}${lang}/marketplace/insured-nomads`,
      insuredNomadsWeb:
        "https://www.insurednomads.com/get-started?ref=prospera",
      payoneer: `${window.location.origin}${lang}/marketplace/payoneer`,
      payoneerWeb: "http://tracking.payoneer.com/SH2tj",
      prosperaEmploymentSolutions: `${window.location.origin}${lang}/marketplace/prospera-employment-solutions`,
      prosperaEmploymentSolutionsWeb: "https://www.pes.hn/",
      prosperaInsuranceCompany: `${window.location.origin}${lang}/marketplace/prospera-insurance-company`,
      partnership: "https://prospera.hn/contact",
      residency: `${window.location.origin}${lang}/residency`,
      openNode: `${window.location.origin}${lang}/marketplace/open-node`,
      openNodeWeb:
        "https://www.opennode.com/",
      requestFinance: `${window.location.origin}${lang}/marketplace/request-finance`,
      requestFinanceWeb:
        "https://app.request.finance/signup",
      candypay: `${window.location.origin}${lang}/marketplace/candypay`,
      candypayWeb:
        "https://candypay.fun/?ref=prospera",
      coinable: `${window.location.origin}${lang}/marketplace/coinable`,
      coinableWeb:
        "https://coinablepay.com/register",
      reapTechnologies: `${window.location.origin}${lang}/marketplace/reap-technologies`,
      reapTechnologiesWeb:
        "https://www.reap.global",
      amityAgeAcademy: `${window.location.origin}${lang}/marketplace/amity-age-academy`,
      amityAgeAcademyWeb:
        "https://www.amityage.com/academy/",
      sphere: `${window.location.origin}${lang}/marketplace/sphere`,
      sphereWeb:
        "http://spherepay.co/",
    };
  }
};
